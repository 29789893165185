'use client';

import { useRouter } from '@/i18n/routing';

export default function Button({ urlPath, children }) {
  const router = useRouter();
  return (
    <button
      className="btn border-0 button-style flex items-center text-center hover:cursor-pointer"
      onClick={() => router.push('/' + urlPath)}
    >
      {children}
    </button>
  );
}
