import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Abbott.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Air_Plus.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Aptar_Beauty_HOME.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/bgServices.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Bosch.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Deloitte.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/dotsBG.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/home-banner-1.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Honeywell.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/buttons/home-buttons/button.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/hero-banner/banner.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/published-report/published-section.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/testimonial/testi-sections.scss");
